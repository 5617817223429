import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import Helmet from "react-helmet"
import CookieConsent from "react-cookie-consent"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import Head from "./head"

const Layout = ({ children, head, spacedFooter }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            prismicGeneralSettings {
                data {
                    site_name {
                        text
                    }
                }
            }
        }
    `)

    const settings = data.prismicGeneralSettings.data

    return (
        <>
            <Helmet>
                <body className="antialiased" />
            </Helmet>
            {head && <Head data={head} />}
            <Header siteTitle={settings.site_name?.text || `FastJam`} />
            <main>{children}</main>
            <Footer
                spacedFooter={spacedFooter}
                siteTitle={settings.site_name?.text || `FastJam`}
            />
            <CookieConsent
                disableStyles={true}
                location="none"
                containerClasses="z-20 fixed bottom-4 right-3 w-full max-w-xs p-4 w-full bg-black bg-opacity-75"
                contentClasses="mb-4 text-xs text-white leading-relaxed"
                buttonClasses="px-4 py-1 w-full text-sm font-medium text-black rounded-sm bg-white"
                buttonText="OK"
            >
                Cookies erlauben uns, Inhalte und Anzeigen basierend auf Ihren
                Interessen anzupassen, Social Media-Funktionialität anzubieten
                und unseren Traffic zu analysieren.{" "}
                <Link className="underline" to="/mentions">
                    Mehr erfahren
                </Link>
            </CookieConsent>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
